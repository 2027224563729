<template>
  <span>

    <template v-if="getTransferDetails.transfer_type_name == 'transfer'">
      <div v-if="getTransferDetails.client_name">
        <v-text-field
          disabled
          outlined
          label="payment_source"
          :value="getTransferDetails.payment_source"
        ></v-text-field>

        <v-text-field
          disabled
          outlined
          label="client_name"
          :value="getTransferDetails.client_name"
        ></v-text-field>
        <v-text-field
          disabled
          outlined
          label="client_inn"
          :value="getTransferDetails.client_inn"
        ></v-text-field>
        <v-text-field
          disabled
          outlined
          label="client_inn"
          :value="getTransferDetails.client_inn"
        ></v-text-field>
        <v-text-field
          disabled
          outlined
          label="transfer_name"
          :value="getTransferDetails.transfer_name"
        ></v-text-field>
        <v-text-field
          disabled
          outlined
          label="amount"
          :value="getTransferDetails.amount"
        ></v-text-field>
      </div>
      <div v-else class="row flex-wrap">
        <div class="form-group col-2">
          <label>To'lov manbasi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.payment_source_name"
          />
        </div>

        <div class="form-group col-2">
          <label>Operatsita turi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.order_type_name"
          />
        </div>

        <div class="form-group col-3">
          <label>Operatsita nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.type_statetransfer_name"
          />
        </div>

        <div class="form-group col-2">
          <label>Summa:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.amount"
          />
        </div>

        <div class="form-group col-2">
          <label>Operatsiya sanasi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.oper_date | dateFilter"
          />
        </div>

        <div class="form-group col-4">
          <label>Comment:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.comment"
          />
        </div>
      </div>
    </template>

    <template v-if="getTransferDetails.transfer_type_name == 'corp_card_operation'">
      <div v-if="getTransferDetails.client_name" class="row">
        <div class="form-group col-4">
          <label>Manba:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.payment_source"
          />
        </div>

        <div class="form-group col-4">
          <label>Mijoz nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.client_name"
          />
        </div>

        <div class="form-group col-4">
          <label>Mijoz inn:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.client_inn"
          />
        </div>
        <div class="form-group col-4">
          <label>Transfer nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.transfer_name"
          />
        </div>

        <div class="form-group col-4">
          <label>Transfer raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.transfer"
          />
        </div>
        <div class="form-group col-4">
          <label>Shartnoma raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.contract"
          />
        </div>

        <div class="form-group col-4">
          <label>Sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.oper_date"
          />
        </div>

        <div class="form-group col-4">
          <label>Yaratilgan sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.created_at"
          />
        </div>
        <div class="form-group col-4">
          <label>Summa:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.amount"
          />
        </div>
      </div>
      <div v-else class="row">
        <div class="form-group col-4">
          <label>Karta raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.card_number"
          />
        </div>

        <div class="form-group col-4">
          <label>Karta egasi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.card_owner"
          />
        </div>

        <div class="form-group col-4">
          <label>Operatsita turi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.operation_type"
          />
        </div>

        <div class="form-group col-4">
          <label>Sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.oper_date | dateFilter"
          />
        </div>

        <div class="form-group col-4">
          <label>Tranzaksiya raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.transaction"
          />
        </div>
        <div class="form-group col-4">
          <label>Tranzaksiya nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.operation_perpose"
          />
        </div>

        <div class="form-group col-4">
          <label>Summa:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.amount"
          />
        </div>

        <div class="form-group col-4">
          <label>Comment:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.comment"
          />
        </div>
      </div>
    </template>
    
    <template v-if="getTransferDetails.transfer_type_name == 'client_contract'">
      <div v-if="getTransferDetails.client_name" class="row">
        <div class="form-group col-4">
          <label>Manba:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.payment_source"
          />
        </div>

        <div class="form-group col-4">
          <label>Mijoz nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.client_name"
          />
        </div>

        <div class="form-group col-4">
          <label>Mijoz inn:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.client_inn"
          />
        </div>
        <div class="form-group col-4">
          <label>Transfer nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.transfer_name"
          />
        </div>

        <div class="form-group col-4">
          <label>Transfer raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.transfer"
          />
        </div>
        <div class="form-group col-4">
          <label>Shartnoma raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.contract"
          />
        </div>

        <div class="form-group col-4">
          <label>Sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.oper_date"
          />
        </div>

        <div class="form-group col-4">
          <label>Yaratilgan sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.created_at"
          />
        </div>
        <div class="form-group col-4">
          <label>Summa:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.amount"
          />
        </div>
      </div>
      <div v-else class="row">
        <div class="form-group col-4">
          <label>Karta raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.card_number"
          />
        </div>

        <div class="form-group col-4">
          <label>Karta egasi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.card_owner"
          />
        </div>

        <div class="form-group col-4">
          <label>Operatsita turi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.operation_type"
          />
        </div>

        <div class="form-group col-4">
          <label>Sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.oper_date | dateFilter"
          />
        </div>

        <div class="form-group col-4">
          <label>Tranzaksiya raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.transaction"
          />
        </div>
        <div class="form-group col-4">
          <label>Tranzaksiya nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.operation_perpose"
          />
        </div>

        <div class="form-group col-4">
          <label>Summa:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.amount"
          />
        </div>

        <div class="form-group col-4">
          <label>Comment:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.comment"
          />
        </div>
      </div>
    </template>

    <template v-if="getTransferDetails.transfer_type_name == 'provider_contract'">
      <div v-if="getTransferDetails.client_name" class="row">
        <div class="form-group col-4">
          <label>Manba:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.payment_source"
          />
        </div>

        <div class="form-group col-4">
          <label>Mijoz nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.client_name"
          />
        </div>

        <div class="form-group col-4">
          <label>Mijoz inn:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.client_inn"
          />
        </div>
        <div class="form-group col-4">
          <label>Transfer nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.transfer_name"
          />
        </div>

        <div class="form-group col-4">
          <label>Transfer raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.transfer"
          />
        </div>
        <div class="form-group col-4">
          <label>Shartnoma raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.contract"
          />
        </div>

        <div class="form-group col-4">
          <label>Sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.oper_date"
          />
        </div>

        <div class="form-group col-4">
          <label>Yaratilgan sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.created_at"
          />
        </div>
        <div class="form-group col-4">
          <label>Summa:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.amount"
          />
        </div>
      </div>
      <div v-else class="row">
        <div class="form-group col-4">
          <label>Shartnoma raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.contract_number"
          />
        </div>

        <div class="form-group col-4">
          <label>Yetkazib beruvchi nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.provider_name"
          />
        </div>

        <div class="form-group col-4">
          <label>Sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.oper_date | dateFilter"
          />
        </div>

        <div class="form-group col-4">
          <label>Summa:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.amount"
          />
        </div>
      </div>
    </template>

    <template v-if="getTransferDetails.transfer_type_name == 'conteragent_contract'">
      <div v-if="getTransferDetails.client_name" class="row">
        <div class="form-group col-4">
          <label>Manba:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.payment_source"
          />
        </div>

        <div class="form-group col-4">
          <label>Mijoz nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.client_name"
          />
        </div>

        <div class="form-group col-4">
          <label>Mijoz inn:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.client_inn"
          />
        </div>
        <div class="form-group col-4">
          <label>Transfer nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.transfer_name"
          />
        </div>

        <div class="form-group col-4">
          <label>Transfer raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.transfer"
          />
        </div>
        <div class="form-group col-4">
          <label>Shartnoma raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.contract"
          />
        </div>

        <div class="form-group col-4">
          <label>Sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.oper_date"
          />
        </div>

        <div class="form-group col-4">
          <label>Yaratilgan sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.created_at"
          />
        </div>
        <div class="form-group col-4">
          <label>Summa:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.amount"
          />
        </div>
      </div>
      <div v-else class="row">
        <div class="form-group col-4">
          <label>Shartnoma raqami:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.contract_number"
          />
        </div>

        <div class="form-group col-4">
          <label>Kontragent nomi:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.contragent_name"
          />
        </div>

        <div class="form-group col-4">
          <label>Sana:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.oper_date | dateFilter"
          />
        </div>

        <div class="form-group col-4">
          <label>Summa:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.amount"
          />
        </div>

        <div class="form-group col-4">
          <label>Comment:</label>
          <input
            type="text"
            disabled
            class="form-control form-control-solid form-control-lg"
            :value="getTransferDetails.comment"
          />
        </div>
      </div>
    </template>

  </span>
</template>

<script>
export default {
  props: {
    getTransferDetails: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>


<style scoped>
.form-group {
    margin-bottom: 0;
}
</style>