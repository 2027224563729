<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Vipiska malumotlari</h3>
            </div>
          </div>

          <!-- <pre>{{ getTransferDetails }}</pre> -->

          <div class="card-body">
            <vipiskaForm :getTransferDetails="getTransferDetails" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import vipiskaForm from '@/view/pages/finance/Vipiska/vipiskaFormTemplate'

export default {
  components: {
    vipiskaForm
  },
  beforeCreate() {
    this.$store.dispatch('getTransferDetails', this.$route.params.id)
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: ' Moliya' },
      { title: 'Vipiska' },
      { title: ' Vipiska malumotlari' }
    ])
  },
  computed: {
    getTransferDetails() {
      return this.$store.state.requests.transferDetails
    }
  }
}
</script>

<style scoped>
.form-group label,
.form-control {
  font-size: 1.1rem !important;
}
.theme--light /deep/ .v-input--is-disabled input {
  color: #000 !important;
}
.theme--light
  /deep/
  .v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #000 !important;
}

.theme--light /deep/ .v-label--is-disabled {
  color: #000 !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>
